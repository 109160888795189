import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment, {Moment} from "moment";
import {Event, Registration} from "../../types/registration-types";
import {ZoozaContext} from "../../services/zooza.context";
import {ZoozaContextTypes} from "../../types/types";
import {useSearchParams} from "react-router-dom";
import {ProgressButtons} from "../../pages/create-registration/progress-buttons";
import {ZoozaApi} from "../../services/Axios";
import {registerLocale} from "react-datepicker";
import {cs, de, enGB, pl, ro, sk} from "date-fns/locale";
import {ZoozaTable, ZoozaTableActions, ZoozaTableBody, ZoozaTableHeader,} from "../zooza-table-components";
import {ReactComponent as Loader} from "../../assets/img/loader.svg";
import {CheckAvability} from "../../types/courses-types";

import {DeleteEventModal} from "./DeleteEventModal";
import {EventRow} from "./event-row";
import {AddEventsModal} from "./add-events-modal";
import {handleError} from "../../services/common-functions";

registerLocale("sk", sk);
registerLocale("cs", cs);
registerLocale("pl", pl);
registerLocale("en", enGB);
registerLocale("de", de);
registerLocale("ro", ro);
const lang = localStorage.getItem("i18nextLng") || "en";

export const EventsCard = ({
                               events,
                               limit,
                               setEvents,
                               progressButtons,
                               setProgress,
                               progress,
                               progressValue,
                               defaultDate,
                               newEvent,
                               schedule_id,
                               course_id,
                               registration_id,
                               paied,
                               toPay,
                               getEvents,
                               unit_price,
    registration,
    afterSave,
                           }: {
    events?: Event[];
    limit?: number;
    setEvents?: any;
    progressButtons?: boolean;
    setProgress?: (progress: number) => void;
    progress?: number;
    progressValue?: number;
    defaultDate?: string;
    newEvent?: boolean;
    schedule_id?: number;
    course_id?: number;
    registration_id?: number;
    paied?: number;
    toPay?: number;
    getEvents?: () => void;
    unit_price?: number;
    afterSave?: () => void;
    registration?: Registration;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {members, places} = useContext<ZoozaContextTypes>(ZoozaContext);
    const [checkAvabilityMember, setCheckAvability] = useState<
        CheckAvability[] | undefined
    >();
    const [deleteModal, setDeleteModal] = useState<Event | null>(null);

    const {t} = useTranslation();
    const EventChange =  async (
        param: string,
        value: number | Date | null | Moment | string,
        id?: number
    ) => {
        setEvents((prevEvents: Event[] | undefined) => {
            if (!prevEvents) {
                return [];
            }
            return prevEvents.map((event) => {
                if (event.id === id) {
                    return {
                        ...event,
                        [param]: value,
                    };
                }
                return event;
            });
        });
        const event = events?.find(e => e.id === id);
        if(param === "date"){
            const times = {
                start: moment(value),
                duration: event?.duration
            }
            await checkAvability(events,event?.trainer_id, times)
        }
        else if(param === "duration"){
            const times = {
                start: moment(event?.date),
                duration: Number(value)
            }
            await checkAvability(events,event?.trainer_id, times)
        }else{
            await checkAvability(events,event?.trainer_id )
        }
console.log("call")
    };
    const checkAvability = async (
        events: Event[] | undefined,
        member_id: number | undefined,
        event_times?: {start: Moment; duration: number | undefined}
    ) => {
        const times = events?.map((event) => ({
            start: moment(event_times? event_times.start:event.date).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.date)
                .add(event_times? event_times.duration:event.duration, "minutes")
                .format("YYYY-MM-DD HH:mm:ss"),
        }));
        try {
            const {data} = await ZoozaApi.post(
                `users/${member_id}/availability/check`,
                times
            );
            setCheckAvability(data);
        } catch (e: any) {
            handleError(e.response.data, "alert");
        }
    };

    const addEvent = () => {
        const member = members?.find(
            (member) => member.id === Number(searchParams.get("member"))
        );
        if (!events || events.length === 0) {
            const initialDate = moment(defaultDate);
            const isValidDate = initialDate.isValid();

            setEvents([
                {
                    id: 1,
                    date: isValidDate
                        ? initialDate.format("YYYY-MM-DD HH:mm:ss")
                        : moment().format("YYYY-MM-DD HH:mm:ss"),
                    duration: 60,
                    trainer_id: Number(searchParams.get("member")) || member?.id || 0,
                    place_id: Number(searchParams.get("place")?.split("_")[0]) || 0,
                    room_id: Number(searchParams.get("place")?.split("_")[1]) || 0,
                    new: true,
                },
            ]);
        } else {
            setEvents((prevEvents: Event[] | undefined) => {
                if (!prevEvents) {
                    return [];
                }

                const lastEvent = prevEvents[prevEvents.length - 1];
                const clonedLastEvent = {...lastEvent};

                const nextDay = moment(clonedLastEvent.date).add(1, "days");
                clonedLastEvent.date = nextDay.format("YYYY-MM-DD HH:mm:ss");
                clonedLastEvent.id = Number(lastEvent.id) + 1;

                return [...prevEvents, {...clonedLastEvent, new: true}];
            });
        }
    };
    const [smallLoading, setSmallLoading] = useState(false);
    const [successSave, setSuccessSave] = useState("");
    const [newEvents, setNewEvents] = useState<EventReqType[] | undefined>();
    const [openNewEventModal, setOpenNewEventModal] = useState(false);
    const [oldEvents, setOldEvents] = useState<EventReqType[] | undefined>();

    const saveEvents = async () => {
        try {
            setSmallLoading(true);
            const newEvents = events?.filter((event) => event.new === true);
            const oldEvents = events?.filter((event) => event.new !== true);

            const EventsReq = newEvents?.map((event: Event) => ({
                duration: event.duration,
                date: moment(event.date),
                date_string: moment(event.date).locale(lang).format("YYYY-MM-DD"),
                day: moment(event.date).locale(lang).format("DD"),
                time_string:
                    moment(event.date).hours() * 60 + moment(event.date).minutes(),
                course_id: course_id,
                schedule_id: schedule_id,
                trainer_id: event.trainer_id,
                trainer_rate_type_id: event.trainer_rate_type_id,
                place_id: event.place_id,
                room_id: event.room_id,
                has_event: true,
                month: moment(event.date).locale(lang).format("MM"),
                time: moment(event.date).locale(lang).format("HH:mm"),
                time_end: moment(event.date)
                    .locale(lang)
                    .add(event.duration, "minutes")
                    .format("HH:mm"),
                weekend:
                    moment(event.date).day() === 6 || moment(event.date).day() === 0,
                full_date: moment(event.date)
                    .locale(lang)
                    .format("dddd, DD. MMMM YYYY"),
                short_date: moment(event.date).locale(lang).format("DD. MMMM YYYY"),
                is_holiday: false,
                holiday: null,
            })) as unknown as EventReqType[];
            const oldEventsReq = oldEvents?.map((event: Event) => ({
                id: event.id,
                name: null,
                course_id: course_id,
                schedule_id: schedule_id,
                trainer_id: event.trainer_id,
                status: "scheduled",
                date: moment(event.date).locale(lang).format("YYYY-MM-DD HH:mm:ss"),
                place_id: event.place_id,
                trainer_rate_type_id: 0,
                room_id: event.room_id,
                room: event.room_id,
                duration: event.duration,
                time_string:
                    moment(event.date).hours() * 60 + moment(event.date).minutes(),
            })) as unknown as EventReqType[];

            if (newEvents?.length !== 0) {
                setNewEvents(EventsReq);
                setOpenNewEventModal(true);
                setSmallLoading(false);
            }
            if (oldEvents?.length !== 0) {
                setOldEvents(oldEventsReq);
                const {data} = await ZoozaApi.put(`/events`, {
                    events: oldEventsReq,
                });
                if (data) {
                    setSuccessSave(t("events.saving.title.success"));
                    setTimeout(() => {
                        setSuccessSave("");
                    }, 2000);
                    if (data.length !== oldEventsReq?.length) {
                        await getEvents?.();
                    }
                }
                setSmallLoading(false);
            }
        } catch (error: any) {
            console.log(error);
            const message = handleError(error.response.data, "message");
            setSuccessSave(t("events.saving.title.error") + " " + message);
        }
    };
    const eventsWitAvability = events?.map((event, i_e) => {
        const avability = checkAvabilityMember?.find(
            (avability: any, i_a) =>
                moment(avability.start).format("YYYY-MM-DD HH:mm:ss") ===
                moment(event.date).format("YYYY-MM-DD HH:mm:ss")
        );
        return {...event, avability: avability};
    });

    return (
        <>
            <ZoozaTable disabled={progress !== progressValue}>
                <ZoozaTableHeader>
                    <h3>{t("events.title")}</h3>
                </ZoozaTableHeader>

                {progress === progressValue && (
                    <>
                        <ZoozaTableBody>
                            {events?.length !== 0 &&
                                eventsWitAvability?.map((event, index) => (
                                    <EventRow
                                        eventChange={EventChange}
                                        key={index}
                                        event={event}
                                        newEvent={newEvent}
                                        setEvents={setEvents}
                                        setDeleteModal={setDeleteModal}
                                    />
                                ))}
                        </ZoozaTableBody>
                        <ZoozaTableActions>
                            {" "}
                            {limit && limit > Number(events?.length) && (
                                <button onClick={addEvent} className="z2">
                                    {t("events.buuton.add_event")}
                                </button>
                            )}
                            {!limit && (
                                <button
                                    onClick={addEvent}
                                    className="z2"
                                    disabled={smallLoading}
                                >
                                    {t("events.buuton.add_event")}
                                </button>
                            )}
                            {progressButtons && (
                                <ProgressButtons
                                    disabled={events?.length === 0}
                                    setProgress={setProgress}
                                    next={3}
                                    back={1}
                                />
                            )}
                            {!progressButtons && events?.length !== 0 && (
                                <button
                                    className="z2 primary"
                                    onClick={saveEvents}
                                    disabled={smallLoading}
                                >
                                    {smallLoading ? (
                                        <Loader className="spinner-button"></Loader>
                                    ) : (
                                        t("global.button.save")
                                    )}
                                </button>
                            )}
                        </ZoozaTableActions>

                        <h5>{successSave}</h5>
                    </>
                )}
            </ZoozaTable>
            <DeleteEventModal
                event={deleteModal}
                setOpen={setDeleteModal}
                paid={paied}
                toPay={toPay}
                registration_id={registration_id}
                getEvents={getEvents}
                totalEvents={events?.length}
            />
            <AddEventsModal
                open={openNewEventModal}
                setOpen={setOpenNewEventModal}
                paid={paied}
                events={newEvents}
                toPay={toPay}
                oldEvents={oldEvents}
                order_id={registration_id}
                getEvents={getEvents}
                unit_price={unit_price}
                afterSave={afterSave}
                registration={registration}
            />
        </>
    );
};

export type EventReqType = {
    duration: number;
    date: Moment;
    date_string: string;
    day: string;
    time_string: number;
    course_id: number;
    schedule_id: number;
    trainer_id: number;
    trainer_rate_type_id: number;
    place_id: number;
    room_id: number;
    has_event: boolean;
    month: string;
    time: string;
    time_end: string;
    weekend: boolean;
};
