import moment, { Moment } from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/date-picker.css";
import { Input } from "./input";

import { registerLocale } from "react-datepicker";
import { cs, sk, pl, enGB, de, ro } from "date-fns/locale";
registerLocale("sk", sk);
registerLocale("cs", cs);
registerLocale("pl", pl);
registerLocale("en", enGB);
registerLocale("de", de);
registerLocale("ro", ro);

type DateTimePickerProps = {
  required: boolean;
  date: Date | Moment;
  help: string;
  setDate?: (date: Moment) => void;
  onChange?: (date: Moment) => void;
  format?: string;
};

export function DateTimePicker({
  setDate,
  required,
  date,
  format,
  help,
    onChange
}: DateTimePickerProps) {
  const lang = localStorage.getItem("i18nextLng") || "en";
  return (
    <div className="date_time">
      <DatePicker
        selected={moment(date, "YYYY-MM-DD").toDate()}
        onChange={(date) => onChange? onChange(moment(date)) : setDate?.(moment(date))}
        dateFormat={format ? format : "yyyy-MM-dd"}
        required={required}
        locale={lang}
        customInput={
          <Input
            value={moment(date).format(format ? format : "YYYY-MM-DD") || ""}
            onClick={() => setDate?.(moment(date))}
            onChange={() => setDate?.(moment(date))}
            type={"text"}
            placeholder={moment(date).format(format ? format : "YYYY-MM-DD")}
            id={"datepicker-input"}
          />
        }
        id="datepicker-input" // Specify an ID
      />

      <div className="datepicker_well"></div>
      <div className="time"></div>
      <span className="block_help">{help}</span>
    </div>
  );
}
