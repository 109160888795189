import { AttendanceEvent } from "../../types/registration-types";
import {
  ZoozaCardCell,
  ZoozaCardRow,
} from "../../components/zooza-card-components";
import { t } from "i18next";
import { Link, useParams } from "react-router-dom";
import { msg } from "../../services/common-functions";
import { AttendanceManager } from "../../components/attendance-manager";
import React, { useContext } from "react";
import { EventContext } from "./event-context";

export const AttendanceRow = ({
  attendance,
}: {
  attendance: AttendanceEvent;
}) => {
  const { getAttendance, setIds, setOpenPaymentModal } =
    useContext(EventContext);
  const { id } = useParams();
  const onAddPaymentClick = () => {
    setIds({
      event_id: Number(id),
      registration_id: attendance?.registration_id,
      user_id: attendance?.user_id,
    });
    setOpenPaymentModal(true);
  };

  return (
    <ZoozaCardRow>
      <ZoozaCardCell>
        <span aria-label={t("event.title.client_name")}>
          {t("event.title.client_name")}
        </span>
        <span>
          <Link
            className="z2"
            to={`/registrations/${attendance.registration_id}`}
          >
            {attendance.full_name}
            <br />
            {attendance.email}
            <br />
            {attendance.phone}
            <br />
            {attendance.extra_fields?.full_name?.value}
          </Link>
        </span>
      </ZoozaCardCell>
      <ZoozaCardCell>
        <span aria-label={t("global.title.payment")}>
          {t("global.title.payment")}
        </span>
        {msg(attendance.payment_status)}
        <button onClick={onAddPaymentClick} className="z2 primary">
          {t("global.button.add_payment")}
        </button>
      </ZoozaCardCell>
      <ZoozaCardCell>
        <span aria-label={t("global.title.attendance")}>
          {t("global.title.attendance")}
        </span>
        <AttendanceManager
          registration_id={attendance?.registration_id}
          status={attendance.attendance}
          user_id={attendance?.user_id}
          getAttendance={() => getAttendance()}
        />
      </ZoozaCardCell>
    </ZoozaCardRow>
  );
};
