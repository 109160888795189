import React, {useContext, useEffect} from "react";
import {Input} from "../../components/input";
import {ZoozaSelect} from "../../components/inputs";
import {
    ZoozaCard,
    ZoozaCardActions,
    ZoozaCardCell,
    ZoozaCardHeader,
    ZoozaCardRow,
} from "../../components/zooza-card-components";
import {CreateRegistrationContextTypes, Place, Room, ZoozaContextTypes,} from "../../types/types";
import {CreateRegistrationContext} from "./create-registration-context";
import {ZoozaContext} from "../../services/zooza.context";
import {Course} from "../../types/courses-types";
import {useSearchParams} from "react-router-dom";
import {msg} from "../../services/common-functions";
import {useTranslation} from "react-i18next";

type ScheduleCard = {
    progress: number;
    progressing: (e: any) => void;
};
type GroupedOptions = {
    [key: string]: Course[];
};

export const ScheduleCard: ({progress, progressing}: ScheduleCard) => React.JSX.Element = ({
                                                                                               progress,
                                                                                               progressing
                                                                                           }: ScheduleCard) => {
    const {
        pid,
        setPid,
        setScheduleName,
        scheduleName,
        course,
        setCourse,
        setMember,
        member,
        place,
    } = useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);
    const {places, courses, members, userData} =
        useContext<ZoozaContextTypes>(ZoozaContext);
    const [searchParams] = useSearchParams();
    const isMember =
        userData?.user?.role === "member" ||
        userData?.user?.role === "main_member" ||
        userData?.user?.role === "external_member";
    const groupedCourses = courses
        ?.filter(
            (course) =>
                !course.archive
        )
        .reduce((acc: GroupedOptions, option) => {
            const accOptionGroup = acc[option.registration_type] || [];

            return {
                ...acc,
                [option.registration_type]: [...accOptionGroup, option],
            };
        }, {});
    const {t} = useTranslation();
    useEffect(() => {
        if (isMember) {
            setMember?.({
                id: userData?.user.id || 0,
                first_name: userData?.user.first_name || "",
                last_name: userData?.user.last_name || "",
                email: userData?.user.email || "",
                phone: userData?.user.phone || "",
                feedback: false,
                created: "",
                updated: "",
                role: userData?.user.role as any,
            });

            searchParams.set("member", userData?.user.id.toString());
        }
    }, [userData, course, place]);

    useEffect(() => {
        const course = courses?.find((course) => course.id === Number(searchParams.get("course")));
        if (course) {
            setCourse?.(course)
        }

    }, [searchParams.get("course"), courses]);

    useEffect(()=>{

        const member = members?.find((member) => member.id === Number(searchParams.get("member")));
        if(member){
            setMember?.(member)
        }
    },[searchParams.get("member"), members]);




    return (
        <ZoozaCard isDisabeld={progress !== 1}>
            <ZoozaCardHeader title={t("registrations.create.title.schedules")}/>
            {progress === 1 && (
                <>
                    <ZoozaCardRow>
                        <ZoozaCardCell>
                            <span>{t("global.schedules.name")}</span>
                            <Input
                                onChange={(e) => setScheduleName?.(e.target.value)}
                                value={scheduleName}
                                placeholder={t("global.schedules.name")}
                                type="text"
                            />
                        </ZoozaCardCell>
                        <ZoozaCardCell>
              <span>
                {t("global.schedules.place")} <em>*</em>
              </span>
                            <ZoozaSelect
                                param={"place"}
                                onChange={(e: any) => {
                                    setPid?.(e.target.value);
                                }}
                                value={pid || searchParams.get("place") || ""}
                            >
                                <option value="" disabled>
                                    {t("registrations.create.select.choose_place")}
                                </option>
                                {places?.map((place: Place) => (
                                    <optgroup key={place.id} label={place.name}>
                                        <option value={place.pid}>{place.name}</option>
                                        {place.rooms?.map((room: Room) => (
                                            <option key={room.id} value={room.pid}>
                                                {room.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </ZoozaSelect>
                        </ZoozaCardCell>
                        <ZoozaCardCell>
              <span>
                {t("global.title.course")} <em>*</em>
              </span>
                            <ZoozaSelect
                                param="course"
                                value={course?.id || ""}
                                onChange={(e: any) =>
                                    setCourse?.(
                                        courses?.find(
                                            (course) => course.id === Number(e.target.value)
                                        )
                                    )
                                }
                            >
                                <option value="" disabled>
                                    {t("registrations.create.select.choose_course")}
                                </option>
                                {Object.keys(groupedCourses || []).map((option) => (
                                    <optgroup key={option} label={msg(option)}>
                                        {groupedCourses?.[option].map(({id, name}) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </ZoozaSelect>
                        </ZoozaCardCell>
                        <ZoozaCardCell>
              <span>
                {t("global.title.trainer")} <em>*</em>
              </span>
                            <ZoozaSelect
                                param="member"
                                value={isMember ? userData?.user.id : member?.id || ""}
                                onChange={(e: any) =>
                                    setMember?.(
                                        members?.find(
                                            (member) => member.id === Number(e.target.value)
                                        )
                                    )
                                }
                                disabled={members?.length === 0 || isMember}
                            >
                                <option value="" disabled>
                                    {t("registrations.create.select.choose_trainer")}
                                </option>
                                {isMember && (
                                    <option value={userData?.user.id}>
                                        {userData?.user.first_name} {userData?.user.last_name}
                                    </option>
                                )}
                                {!isMember &&
                                    members?.map((member) => (
                                        <option key={member.id} value={member.id}>
                                            {member.first_name} {member.last_name}
                                        </option>
                                    ))}
                            </ZoozaSelect>
                        </ZoozaCardCell>
                    </ZoozaCardRow>
                    <ZoozaCardActions>
                        <button
                            onClick={progressing}
                            id="progress"
                            value={2}
                            className="z2 primary"
                            disabled={!course || !place || !member}
                        >
                            {t("global.button.continue")}
                        </button>
                    </ZoozaCardActions>
                </>
            )}
        </ZoozaCard>
    );
};
