import { ZoozaApi } from "./Axios";
import { useTranslation } from "react-i18next";
import i18n from "../locale";
import { AxiosError, AxiosResponse } from "axios";

export const getTrainers = async () => {
  try {
    const { data } = await ZoozaApi.get(
      "/users?page_size=5000&roles=owner|member|external_member|assistant|main_member"
    );

    return data;
  } catch (err) {
    console.error("Fetch Error:", err);
  }
};
export const handleError = (
  error: ErrorZozoaType,
  type: "alert" | "message" | "console" = "console"
) => {
  if (!Array.isArray(error?.errors)) {
    console.error("Expected error.errors to be an array, got:", error?.errors);
    return;
  }

  const messages = error.errors.map((e) => {
    const value = Object.values(e)[0];
    if (typeof value !== "string") {
      console.error("Expected error value to be a string, got:", value);
      return "Unknown error";
    }
    return value;
  });
  const message = messages.join("\n") + "\n" + error.request_uri;
  switch (type) {
    case "alert":
      alert(message);
      break;
    case "message":
      return messages.join("\n");
  }
  console.error(error);
};

const getTranslatedEnums = () => {
  return {
    full2: i18n.t("enums.registration_types.full2"),
    open: i18n.t("enums.registration_typesopen"),
    single: i18n.t("enums.registration_typessingle"),
    registrations: i18n.t("global.title.registrations"),
    create: i18n.t("global.title.create"),
    dob: i18n.t("enums.extra_fields.dob"),
    slots: i18n.t("enums.extra_fields.slots"),
    full_name: i18n.t("enums.extra_fields.full_name"),
    address: i18n.t("enums.extra_fields.address"),
    business_address: i18n.t("enums.extra_fields.business_address"),
    business_id: i18n.t("enums.extra_fields.business_id"),
    business_name: i18n.t("enums.extra_fields.business_name"),
    identification_number: i18n.t("enums.extra_fields.identification_number"),
    tax_id: i18n.t("enums.extra_fields.tax_id"),
    vat: i18n.t("enums.extra_fields.vat"),
    extra_field_1: i18n.t("enums.extra_fields.extra_field_1"),
    extra_field_2: i18n.t("enums.extra_fields.extra_field_2"),
    extra_field_3: i18n.t("enums.extra_fields.extra_field_3"),
    extra_field_4: i18n.t("enums.extra_fields.extra_field_4"),
    extra_field_5: i18n.t("enums.extra_fields.extra_field_5"),
    phone: i18n.t("global.title.phone"),
    email: i18n.t("global.title.email"),
    first_name: i18n.t("global.title.first_name"),
    last_name: i18n.t("global.title.last_name"),
    canceled: i18n.t("registrations.status.canceled"),
    late: i18n.t("registrations.status.late"),
    registered: i18n.t("registrations.status.registered"),
    waitlist: i18n.t("registrations.status.waitlist"),
    pre_registered: i18n.t("registrations.status.pre_registered"),
    paid: i18n.t("enums.payment_status.paid"),
    unpaid: i18n.t("enums.payment_status.unpaid"),
    partially_paid: i18n.t("enums.payment_status.partially_paid"),
    overpaid: i18n.t("enums.payment_status.overpaid"),
    not_paid: i18n.t("enums.payment_status.not_paid"),
    partialy_paid: i18n.t("enums.payment_status.partialy_paid"),
    going: i18n.t("enums.attendance.going"),
    attended: i18n.t("enums.attendance.attended"),
    noshow: i18n.t("enums.attendance.noshow"),
    discount: i18n.t("payments.transaction_types.discount"),
    discount_correction: i18n.t(
      "payments.transaction_types.discount_correction"
    ),
    CREDIT: i18n.t("payments.transaction_types.CREDIT"),
    CREDIT_VIA_TRANSFER: i18n.t(
      "payments.transaction_types.CREDIT_VIA_TRANSFER"
    ),
    credit: i18n.t("payments.transaction_types.CREDIT"),
    credit_correction: i18n.t("payments.transaction_types.credit_correction"),
    registration_fee: i18n.t("payments.transaction_types.registration_fee"),
    registration_fee_correction: i18n.t(
      "payments.transaction_types.registration_fee_correction"
    ),
    course_payment: i18n.t("payments.transaction_types.course_payment"),
    course_payment_correction: i18n.t(
      "payments.transaction_types.course_payment_correction"
    ),
    payments: i18n.t("global.title.payments"),
    refund: i18n.t("payments.transaction_types.refund"),
    SPOROPAY: i18n.t("payments.transaction_types.SPOROPAY"),
    TATRAPAY: i18n.t("payments.transaction_types.TATRAPAY"),
    UNIPAY: i18n.t("payments.transaction_types.UNIPAY"),
    VIAMO: i18n.t("payments.transaction_types.VIAMO"),
    VUBPAY: i18n.t("payments.transaction_types.VUBPAY"),
    FIRSTDATAONDEMAND: i18n.t("payments.transaction_types.FIRSTDATAONDEMAND"),
    CARDPAY: i18n.t("payments.transaction_types.CARDPAY"),
    calendar: i18n.t("calendar.title.calendar"),
    inbound: i18n.t("enums.inbound"),
    manual: i18n.t("enums.manual"),
    automatic: i18n.t("enums.automatic"),
    import: i18n.t("enums.import"),
    orders: i18n.t("global.title.orders"),
    product_payment: i18n.t("payments.transaction_types.product_payment"),
    service_item: i18n.t("payments.product_types.service_item"),
    document: i18n.t("payments.product_types.document"),
    video: i18n.t("payments.product_types.video"),
    prepaid_credit: i18n.t("payments.product_types.prepaid_credit"),
    entrance_voucher: i18n.t("payments.product_types.entrance_voucher"),
    cash: i18n.t("payments.transaction_types.cash"),
    buyer: i18n.t("registrations.role.label.buyer"),
    customer: i18n.t("registrations.role.label.customer"),
    conflict_event: i18n.t("conflict.type.label.event"),
    conflict_unavailable: i18n.t("conflict.type.label.unavailable"),
    conflict_off_hours: i18n.t("conflict.type.label_off_hours")

  };
};

export const msg = (enum_string: string | undefined) => {
  const enums = getTranslatedEnums();
  if (!enums[enum_string as keyof typeof enums]) {
    if (enum_string) {
      console.log(`%cenum ${enum_string} not found`, "color: orange");
    }

    return enum_string;
  } else {
    return enums[enum_string as keyof typeof enums];
  }
};

// Generated by https://quicktype.io

export interface ErrorZozoaType {
  method: string;
  referer: string;
  HTTP_X_REAL_IP: null;
  error_data: ErrorData;
  query_log: QueryLog[];
  request_uri: string;
  version: number;
  resource: string;
  resource_id: null;
  resource_id_raw: null;
  sub_resource: null;
  sub_resource_id: null;
  sub_resource_id_raw: null;
  api_key: string;
  token: string;
  token_requested_by: string;
  company_id: number;
  post_vars: any[];
  query_vars: QueryVars;
  db_error: any[];
  request_valid: boolean;
  errors: Error[];
  region: string;
  language: string;
  language_id: number;
  user_valid: boolean;
  user: User;
  required_authentication: boolean;
  error_log_raw: string[];
  execution_time: number;
}

export interface ErrorData {
  user: User;
  resource: Resource;
  lang: Lang;
}

export interface Lang {
  "0": Array<null | string>;
  final_lang: number;
  final_code: string;
}

export interface Resource {
  is_public_resource: IsPublicResource;
}

export interface IsPublicResource {
  result: boolean;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company: string;
  company_id: number;
  role: string;
  message: string;
  permissions: { [key: string]: boolean };
}

export interface Error {
  [key: string]: string;
}

export interface QueryLog {
  sql: string;
  args: any[] | ArgsClass;
}

export interface ArgsClass {
  ":api_key"?: string;
  ":application_id"?: string;
  ":id"?: number;
  ":token"?: string;
  ":email"?: string;
  ":user_id"?: string;
  ":company_id"?: string;
}

export interface QueryVars {
  _url: string;
  page_size: string;
  roles: string;
}
