import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ZoozaApi } from "../../services/Axios";
import { useTranslation } from "react-i18next";
import { Event, Registration, Schedule } from "../../types/registration-types";
import "moment/min/locales";
import { RegistrationComponent } from "./registartion-component";
import { ExtraFieldComponent } from "./extra-field-component";
import { ClientComponent } from "./client-component";
import { GroupComponent } from "./group-component";
import { PaymentCompoment } from "./payment-component";
import { EventsCard } from "../../components/event-component/events-card";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import moment from "moment";
import { AddPaymentModal } from "../../components/add-payment-modal";
import { LinkedOrders } from "./linked-orders";
import { Order } from "../../types/orders-types";
import "../../styles/_registration_list.scss";
import { ScheduleProducts } from "./products";
import { ScheduleProductsType } from "../../types/schedule-type";

import { OrderPurchaseModal } from "./order-purchase-modal";
import { LinkedRegistrationsComponent} from "./linked-registrations-component";

export const RegistrationPage = () => {
  const [registration, setRegistration] = useState<Registration>();
  const [events, setEvents] = useState<Event[]>([]);
  const [schedule, setSchedule] = useState<Schedule>();
  const [open, setOpen] = React.useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const { id } = useParams();
  const { courses } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [openProductModal, setOpenProductModal] = React.useState(false);
  const [product, setProduct] = React.useState<
    ScheduleProductsType | undefined
  >(undefined);

  const getRegistration = async () => {
    try {
      const { data: registration }: { data: Registration } = await ZoozaApi.get(
        `/registrations/${id}`
      );
      setRegistration(registration);
      return registration;
    } catch (e) {}
  };
  const getEvents = async (schedule_id: number | undefined) => {
    try {
      const { data: events } = await ZoozaApi.get(
        `events?schedule_id=${schedule_id}`
      );
      setEvents(events);
    } catch (e) {}
  };
  const getSchedule = async (schedule_id: number | undefined) => {
    try {
      const { data: schedule } = await ZoozaApi.get(
        `/schedules/${schedule_id}`
      );
      setSchedule(schedule);
    } catch (e) {}
  };
  const getOrders = async () => {
    try {
      const { data: orders } = await ZoozaApi.get(
        `/orders?registration_id=${id}&1=1`
      );
      setOrders(orders.data);
    } catch (e) {}
  };
  const getSchedueProducts = async (schedule_id: number | undefined) => {
    try {
      const { data: products } = await ZoozaApi.get(
        `/schedules/${schedule_id}/products`
      );
      setProducts(products.data);
      console.log(products);
    } catch (e) {}
  };
  const fetch = async () => {
    Promise.all([getRegistration(), getOrders()]).then(([first]) => {
      const id = first?.schedule_id;
      Promise.all([getEvents(id), getSchedule(id), getSchedueProducts(id)]);
    });
  };
  useEffect(() => {
    fetch();
  }, [id]);

  const { t } = useTranslation();
  const course = courses?.find(
    (course) => course.id === registration?.course_id
  );
  console.log(course);
  const upComingEvent = events?.find((event) =>
    moment(event.date).isAfter(moment())
  );

  const pastEvent = events?.find((event) =>
    moment(event.date).isBefore(moment())
  );

  const event_id =
    upComingEvent !== undefined ? upComingEvent?.id : pastEvent?.id;

  return (
    <div className="z2 app_page_layout">
      <div className="z2 app_page_toolbar"></div>
      {Number(registration?.linked_registrations?.data.length) > 0 && (
      <LinkedRegistrationsComponent registration={registration} />
          )}
      <nav className="z2 quick_links">
        <p>{t("registration.title.quick_links")}</p>

        <Link to="payments">{t("registration.title.payments")}</Link>
        <Link
          className={event_id === undefined ? "disabled" : ""}
          to={event_id !== undefined ? `/calendar/${event_id}` : "#"}
        >
          {t("registration.title.attendance")}
        </Link>
      </nav>
      <div className="app_page_body">
        <div className="app_page_column span_2">
          <RegistrationComponent registration={registration} />
          {course?.use_extra_fields && (
            <ExtraFieldComponent
              extra_fields={registration?.extra_fields}
              course={course}
              registration={registration}
            />
          )}
        </div>
        <div className="app_page_column span_2">
          <ClientComponent registration={registration} />
          <GroupComponent registration={registration} schedule={schedule} />
          <PaymentCompoment registration={registration} setOpen={setOpen} />

          <ScheduleProducts
            products={products}
            registration={registration}
            open={openProductModal}
            setOpen={setOpenProductModal}
            setProductToPay={setProduct}
            getSchedueProducts={getSchedueProducts}
          />
        </div>
        <div className="app_page_column span_4">
          <EventsCard
            setEvents={setEvents}
            events={events}
            limit={course?.registration_type === "single" ? 1 : undefined}
            schedule_id={registration?.schedule_id}
            course_id={registration?.course_id}
            paied={registration?.__calc__paid}
            toPay={registration?.__calc__balance}
            registration_id={registration?.id}
            getEvents={fetch}
            unit_price={course?.unit_price}
            afterSave={fetch}
            registration={registration}

          />
        </div>
        <div className="app_page_column span_4">
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <LinkedOrders orders={orders} />
        </div>
      </div>

      <AddPaymentModal
        open={open}
        setOpen={setOpen}
        order_id={registration?.id}
        type="payment"
        order_type={"course"}
      />
      <OrderPurchaseModal
        open={openProductModal}
        setOpen={setOpenProductModal}
        product={product}
        registration={registration}
        getOrders={getOrders}
      />
    </div>
  );
};
