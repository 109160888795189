import React, {useContext} from "react";
import {
    ZoozaCard,
    ZoozaCardActions,
    ZoozaCardCell,
    ZoozaCardHeader,
    ZoozaCardRow,
} from "../../components/zooza-card-components";
import {Registration} from "../../types/registration-types";
import {convertToCurrency} from "../../services/curency-service";
import {ZoozaContext} from "../../services/zooza.context";
import {ZoozaContextTypes} from "../../types/types";
import {Link, useNavigate} from "react-router-dom";

import {msg} from "../../services/common-functions";
import {useTranslation} from "react-i18next";
import {Order} from "../../types/orders-types";

export const PaymentCompoment = ({
                                     registration,
                                     setOpen,
                                     order,
                                 }: {
    registration?: Registration | undefined;
    setOpen: (open: boolean) => void;
    order?: Order | undefined;
}) => {
    const {t} = useTranslation();
    const {userData} = useContext<ZoozaContextTypes>(ZoozaContext);
    const navigate = useNavigate();
    return (
        <>
            <ZoozaCard>
                <ZoozaCardHeader
                    icon="icon-money-plant"
                    title={t("registration.title.payments")}
                />
                {registration?.payments_managed_by ? (
                        <ZoozaCardRow>
                            <ZoozaCardCell>
                                {t("registration.payment.payments_are_managed")}
                            </ZoozaCardCell>
                        </ZoozaCardRow>)
                    : (
                        <ZoozaCardRow>
                            <ZoozaCardCell>
            <span aria-label={t("registration.payment.payment_status")}>
              {t("registration.payment.payments_status")}
            </span>
                                <p>{msg(registration?.payment_status)}</p>
                            </ZoozaCardCell>
                            <ZoozaCardCell>
            <span aria-label={t("global.title.debt")}>
              {t("global.title.debt")}
            </span>

                                <p>
                                    {" "}
                                    {convertToCurrency(
                                        registration ? registration?.__calc__debt : order?.__calc__debt,
                                        userData?.company.region
                                    )}
                                </p>
                            </ZoozaCardCell>
                            <ZoozaCardCell>
            <span aria-label={t("global.title.paid")}>
              {t("global.title.paid")}
            </span>
                                <p>
                                    {" "}
                                    {convertToCurrency(
                                        registration ? registration?.__calc__paid : order?.__calc__paid,
                                        userData?.company.region
                                    )}
                                </p>
                            </ZoozaCardCell>
                            <ZoozaCardCell>
            <span aria-label={t("global.title.total")}>
              {t("global.title.total")}
            </span>
                                <p>
                                    {" "}
                                    {convertToCurrency(
                                        registration ? registration?.__calc__balance : order?.__calc__balance,
                                        userData?.company.region
                                    )}
                                </p>
                            </ZoozaCardCell>
                        </ZoozaCardRow>)}
                <ZoozaCardActions>
                    {!registration?.payments_managed_by? (
                       <>
                        <button onClick={() => setOpen(true)} className="z2 primary">
                            {t("global.button.add_payment")}
                        </button>
                        <button className="z2">
                            <Link to="payments">
                                {t("order.payment.title.view_payments")}
                            </Link>
                        </button>
                    </>):

                        (<button onClick={() => navigate("/registrations/" + registration.payments_managed_by)}
                               className="z2 primary">

                           {t("registration.payment.button.redirect_to_parent")}

                       </button>)
                    }
                </ZoozaCardActions>
            </ZoozaCard>
        </>
    );
};
