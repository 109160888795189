import React from "react";
import {Link, useParams} from "react-router-dom";
import {Registration} from "../../types/registration-types";
import {msg} from "../../services/common-functions"; // External CSS file for styles

interface LinkedRegistrationsProps {
    registration: Registration | undefined;
}

export const LinkedRegistrationsComponent: React.FC<LinkedRegistrationsProps> = ({ registration }) => {
    const { id } = useParams();

    // Ensure the current registration is added only once
    const linkedRegistrations = React.useMemo(() => {
        if (!registration) return [];

        const data = [...(registration.linked_registrations?.data || [])];

        // Include the parent registration if it exists
        let parentRegistration = data.find((r) => r.id === registration.parent) || registration;
        parentRegistration.linked_role = "parent";
        // Ensure the current registration is included
        if (!data.some((r) => r.id === registration.id)) {
            data.push(registration);
        }

        // Filter out duplicates and ensure the parent is at the start
        return [parentRegistration, ...data.filter((r) => r.id !== parentRegistration.id)];
    }, [registration]);

    return (
        <div className="app_page_body">
            <nav className="persons_list">
                {linkedRegistrations.map((r) => (
                    <Link
                        key={r.id}
                        to={`/registrations/${r.id}`}
                        className={`registration_person ${r.id === Number(id) ? "active" : ""}`}
                    >
                        <h5>{r.__users__full_name} ({r.extra_fields.full_name})</h5>
                        <small>{msg(r.linked_role === "parent"? "buyer":"customer")}</small>
                    </Link>
                ))}
            </nav>
        </div>
    );
};
