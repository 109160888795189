import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {EventContextTypes, VouchersResponse} from "../../types/event-context-types";
import {AttendanceEvent, Registration} from "../../types/registration-types";
import {CreateRegistrationContextTypes, ZoozaContextTypes} from "../../types/types";
import {EventDetail, ScheduleDetailResponse} from "../../types/schedule-type";
import {ZoozaApi} from "../../services/Axios";
import {useParams} from "react-router-dom";
import {handleError} from "../../services/common-functions";
import {ZoozaContext} from "../../services/zooza.context";

export const EventContext = createContext<EventContextTypes>({} as EventContextTypes);

export const EventProvider = ({children}: React.PropsWithChildren<CreateRegistrationContextTypes>) => {
    const {setLoading,courses} = useContext<ZoozaContextTypes>(ZoozaContext);
    const {id} = useParams<{ id: string }>();
    const [registrations, setRegistrations] = useState<Registration[]>([]);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openUseVoucherModal, setOpenUseVoucherModal] = useState(false);
    const [openNoVoucherModal, setOpenNoVoucherModal] = useState(false);
    const [ids, setIds] = useState<{
        event_id: number | undefined,
        registration_id: number | undefined,
        user_id: number | undefined
    }>();
    const [event, setEvent] = useState<EventDetail>();
    const [schedules, setSchedules] = useState<ScheduleDetailResponse>();
    const [vouchers, setVouchers] = useState<VouchersResponse>();
    const [attendance, setAttendance] = useState<AttendanceEvent[]>([]);


    const getSchedule = async (schedule_id: number) => {
        try {
            const {data} = await ZoozaApi.get(`/schedules/${schedule_id}`);
            setSchedules(data);
        } catch (error: any) {
            handleError(error.response.data);
            throw error;
        }
    };

    const getEvent = async () => {
        setLoading?.(true);
        try {
            const {data} = await ZoozaApi.get(`events/${id}`);
            setEvent(data);
            setLoading?.(false);
            return data;
        } catch (error: any) {
            handleError(error.response.data);
            setLoading?.(false);
        }
    };

    useEffect(() => {
        const fetchEvent = async () => {
            const eventData = await getEvent();
            if (eventData) {
                await getSchedule(eventData.schedule_id);
                await getAttendance();
            }
        };
        fetchEvent();
    }, [id]);


    const getAttendance = async () => {
        try {
            const {data: attendance} = await ZoozaApi.get(
                `/attendance?event_id=${id}`
            );

            setAttendance(attendance);
        } catch (error: any) {
            console.error("Failed to fetch attendance:", error);
        }
    };
    const getRegGroup = async () => {
        const {data} = await ZoozaApi.get(
            `/registrations?advanced_search=1&schedule_id=${event?.schedule_id}&status=active`
        );
        setRegistrations(matchRegistrationsWihAttendance(data.results));
    };
    const eventCourse = courses?.find((c) => c.id === event?.course_id);
    useEffect(() => {
        if (eventCourse?.registration_type === "open") {
            getRegGroup();
        }
    }, [attendance, eventCourse]);
    const matchRegistrationsWihAttendance = (registrations: Registration[]): Registration[] => {
        return registrations?.map((reg: any) => {
            const matchedAttendance = attendance?.find(
                (att) => Number(att.registration_id) === Number(reg.registration_id)
            );
            if (matchedAttendance) {
                return { ...reg, attendance: matchedAttendance };
            } else {
                return reg;
            }
        });
    };


    const contextValue = useMemo(
        () => ({
            registrations,
            setRegistrations,
            openPaymentModal,
            setOpenPaymentModal,
            openUseVoucherModal,
            setOpenUseVoucherModal,
            ids,
            setIds,
            event,
            setEvent,
            schedules,
            setSchedules,
            vouchers,
            setVouchers,
            attendance,
            setAttendance,
            getAttendance,
            openNoVoucherModal,
            setOpenNoVoucherModal,
            eventCourse
        }),
        [
            registrations,
            openPaymentModal,
            openUseVoucherModal,
            openNoVoucherModal,
            ids,
            event,
            schedules,
            vouchers,
            attendance,
            eventCourse
        ]
    );

    return <EventContext.Provider value={contextValue}>{children}</EventContext.Provider>;

}



